// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Font Awesome
@import '~@fortawesome/fontawesome-free/css/all';

// Animate
@import 'animate';

html, body {
    height: 100%;
}

body {
    &::-webkit-scrollbar {
        background: #f79633;
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background: #eee;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #aaa;
    }
}

body.app-body {
    margin: 0;

    @media only screen and (min-width : map-get($grid-breakpoints, "lg")) {
        padding-left: $sidebar-width;
    }
}

small, .small {
    font-weight: 700;
}

.sidebar {

    @media only screen and (min-width : map-get($grid-breakpoints, "lg")) {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: $sidebar-width; 
        overflow-y: scroll;
        background: #f4f4f4;
    }
}

.sidebar__content {

    @media only screen and (min-width : map-get($grid-breakpoints, "lg")) {
        padding: 2rem 1.5rem;
    }
}

.content {
    width: 100%;
    height: auto;
    padding: 20px;

    @media only screen and (min-width : map-get($grid-breakpoints, "lg")) {
        padding: 2.25rem 3rem;
    }
}

.h-main-nav {

    .input-group-text {
        border: none;
        background: none;
        padding: 0 .5rem;
        font-size: 14px;
        margin-left: -33px;
    }

    .nav-link {
        color: #666;
        transition: color .2s ease-out;
        
        .nav-icon { opacity: .35; transition: opacity .2s ease-out; }

        &:hover, &:active, &:focus {
            color: #000;

            .nav-icon { opacity: 1; }
        }
    }

    .form-control {
        border: none; background: none;

        &:hover, &:active, &:focus {
            box-shadow: none;
            outline: 0;
            color: #000;
        }
    
    }

}

// Nav
.nav-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin: 0 .5rem -12px 0;
}
.nav-item-sub {
    padding-left: 2.75rem;
    font-size: 90%;

    .nav-link {
        padding: .25rem;
    }
}

// Breadcrumbs
.h-breadcrumb {

    font-size: 90%;
    margin-bottom: 40px;
    
    a {
        color: #868e96;
        text-decoration: none;
        @include transition(color .2s ease-in-out, border-color .2s ease-in-out);
        border-bottom: #868e96 1px solid;

        &:hover, &:active, &:focus {
            color: #007bff;
            border-color: transparent;
        }
    }
    
    .breadcrumb {
        padding: 0;
        background: none;
    }
}

// Forms
.h-form {

    label {
        text-transform: uppercase;
        font-size: 80%;
        font-weight: bold;
        margin-bottom: 3px;
    }

    .form-text {
        font-size: 85%;
        color: #aaa;
        line-height: 1rem;
    }

    .input-group-text {
        background: white;
        font-size: 90%;
    }
}

.custom-control-label {
    line-height: 2;
}

// Dropdown
.h-dropdown-menu {
    font-size: 100%;
    font-weight: bold !important;
}

// Tables
.h-table {
    
    white-space: nowrap;

    .h-thumb {
        width: 32px;
    }

    .h-action {
        width: 150px;
        border-left: $gray-200 1px solid;
        text-align: center;
    }

    .h-color {
        display: block;
        width: 14px;
        height: 14px;
        background: white;
        border-radius: 25%;
        box-shadow: rgba(0, 0, 0, 0.25) 1px 1px 1px;
    }

    th {
        border-top: none;
        text-transform: uppercase;
        font-size: 80%;
        color: $gray-700;
        border-bottom: $gray-200 1px solid;
    }

    td {
        vertical-align: middle;
        line-height: $line-height-base * 0.8;
        font-size: 90%;
    }
}

// Elements
// Card elements
.h-card {
    font-size: 90%;

    .card-header {
        background: #fff;
        font-weight: 700;
    }
}
.h-nav-card {
    font-weight: bold;
    padding: 10px;
    background: $gray-100;
}
.h-nav-table, .h-pagination {
    border-bottom: $gray-200 1px solid;
    padding: 15px;
}
.h-nav-table {
    margin: -20px -20px 20px -20px;

    .btn { font-weight: 700; }
}
.h-pagination {
    background: $gray-100;
    border-top: $gray-200 1px solid;
    margin: 20px -20px -20px -20px;
    padding: 15px;
}
.h-param {
    padding: .5rem .75rem;
    background: $gray-100;
    border-radius: $border-radius;
}
.h-param-titulo {
    display: block;
    color: $gray-500;
    font-size: 70%;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: -3px;
}



// Modals and flash messages

.h-flash-message {
    position: fixed;
    bottom: 30px;
    right: 30px;
    max-width: 460px;
    background: $gray-200;
    padding: 1rem;
    border-radius: $border-radius;

    &.success a, &.warning a, &.danger a, &.info a {
        color: #fff;
        text-decoration: underline;
    }

    &.success {
        color: lighten($success, 50);
        background: $success;
    }
    &.warning {
        color: lighten($warning, 50);
        background: $warning;
    }
    &.danger {
        color: lighten($danger, 50);
        background: $danger;
    }
    &.info {
        color: lighten($info, 50);
        background: $info;
    }
}

.h-flash-message-close {
    position: absolute;
    width: 27px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -12px;
    top: -12px;
    color: #fff;
    background: $gray-700;
    border-radius: 50%;
    cursor: pointer;

}

.h-error-modal {

    .modal-header {
        color: #fff;
        border-bottom: rgba(255, 255, 255, .10) 1px solid;
    }

    .modal-content {
        color: #fff;
        background: darken($red, 10);
        border: none;
        box-shadow: rgba(0, 0, 0, 0.25) 0 0 30px;
    }

    .close {
        color: #fff;
        text-shadow: none;
    }
}
