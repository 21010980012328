// Typography
$font-family-sans-serif: 'Karla', sans-serif;
$font-size-base: 0.95rem;
$font-weight-base: 400;
$line-height-base: 1.5;

// Colors
// $blue: #3490dc;
// $indigo: #6574cd;
// $purple: #9561e2;
// $pink: #f66d9b;
// $red: #e3342f;
// $orange: #f6993f;
// $yellow: #ffed4a;
// $green: #38c172;
// $teal: #4dc0b5;
// $cyan: #6cb2eb;

// Font Awesome
$fa-font-path: "../../fonts" !default;
$fa-font-size-base: 10px !default;

// Sidebar
$sidebar-width: 280px;
